<template>
  <static-template>
    <h1>{{ $t("ppMainHeader") }}</h1>

    <p>{{ $t("ppIntro1") }}</p>

    <p>
      {{ $t("ppIntro2") }}
    </p>

    <p>
      {{ $t("ppIntro3") }}
    </p>

    <p>
      {{ $t("ppIntro4") }}
    </p>

    <h2>
      {{ $t("ppHeader1") }}
    </h2>
    <p />

    <p>
      {{ $t("ppP11") }}
    </p>

    <p>
      {{ $t("ppP12") }}
    </p>

    <p>
      {{ $t("ppP13") }}
    </p>

    <h2>{{ $t("ppHeader2") }}</h2>

    <p>
      {{ $t("ppP21") }}
    </p>

    <h2>{{ $t("ppHeader3") }}</h2>

    <p>
      {{ $t("ppP31") }}
    </p>

    <ul>
      <li>
        {{ $t("ppP32") }}
      </li>
      <li>{{ $t("ppP33") }}</li>
      <li>
        {{ $t("ppP34") }}
      </li>
      <li>
        {{ $t("ppP35") }}
      </li>
      <li>
        {{ $t("ppP36") }}
      </li>
      <li>
        {{ $t("ppP37") }}
      </li>
      <li>
        {{ $t("ppP38") }}
      </li>
      <li>
        {{ $t("ppP39") }}
      </li>
      <li>
        {{ $t("ppP310") }}
      </li>
      <li>
        {{ $t("ppP311") }}
      </li>
      <li>
        {{ $t("ppP312") }}
      </li>
      <li>
        {{ $t("ppP313") }}
      </li>
      <li>
        {{ $t("ppP314") }}
      </li>
      <li>
        {{ $t("ppP315") }}
      </li>
    </ul>

    <h2>{{ $t("ppHeader4") }}</h2>

    <p>
      {{ $t("ppP41") }}
    </p>

    <h2>{{ $t("ppHeader5") }}</h2>

    <p>
      {{ $t("ppP51") }}
    </p>

    <h2>{{ $t("ppHeader6") }}</h2>

    <p>
      {{ $t("ppP61") }}
    </p>

    <h2>{{ $t("ppHeader7") }}</h2>

    <p>
      {{ $t("ppP71") }}
    </p>

    <p>
      {{ $t("ppP72") }}
    </p>

    <p>
      {{ $t("ppP73") }}
    </p>

    <p>
      {{ $t("ppP74") }}
    </p>

    <ul>
      <li>{{ $t("ppP75") }}</li>
      <li>{{ $t("ppP76") }}</li>
      <li>{{ $t("ppP77") }}</li>
    </ul>

    <p>
      {{ $t("ppP78") }}
    </p>

    <p>
      {{ $t("ppP79") }}
    </p>

    <p>
      {{ $t("ppP710") }}
    </p>

    <p>
      {{ $t("ppP711") }}
    </p>

    <p>
      {{ $t("ppP712") }}
    </p>

    <p>
      {{ $t("ppP713") }}
    </p>

    <h2>{{ $t("ppHeader8") }}</h2>

    <p>
      {{ $t("ppP81") }}
    </p>

    <p>
      {{ $t("ppP82") }}
    </p>

    <p>
      {{ $t("ppP83") }}
    </p>

    <h2>{{ $t("ppHeader9") }}</h2>

    <p>
      {{ $t("ppP91") }}
    </p>

    <h2>{{ $t("ppHeader10") }}</h2>

    <p>
      {{ $t("ppP101") }}
    </p>

    <h2>{{ $t("ppHeader11") }}</h2>

    <p>
      {{ $t("ppP111") }}
    </p>

    <p>
      {{ $t("ppP112") }}
    </p>

    <p>
      {{ $t("ppP113") }}
    </p>

    <p>
      {{ $t("ppP114") }}
    </p>

    <h2>{{ $t("ppHeader12") }}</h2>

    <p>
      {{ $t("ppP121") }}
    </p>

    <p>
      {{ $t("ppP122") }}
    </p>

    <h2>{{ $t("ppHeader13") }}</h2>

    <p>
      {{ $t("ppP131") }}
    </p>

    <h2>{{ $t("ppHeader14") }}</h2>

    <p>
      {{ $t("ppP141") }}
    </p>

    <a href="mailto:contact@neoone.art">{{ $t("ppP142") }}</a>

    <p>
      <strong>{{ $t("ppP143") }}</strong>
    </p>
    <br />
    <p>
      {{ $t("ppDate") }}
    </p>
  </static-template>
</template>

<script>
export default {
  name: "PrivacyPolicyPage",
};
</script>
